import {TransactionModel} from './Transaction.model';
import {HttpError} from '../http/HttpError';
import {TransactionRequest} from './TransactionRequest';

export class TransactionService {
    public static async createTransaction(invoiceId: string, issuerID: string): Promise<TransactionModel> {
        const transactionRequest: TransactionRequest = {
            InvoiceId: invoiceId,
            IssuerID: issuerID,
            MerchantReturnURL: `${window.location.origin}/paymentdone${window.location.search}`, // TODO See Merchant Integratie gids 5.6 merchantReturnURL has 2 params => entranceCode en ec
        }
        const response = await fetch('transaction', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(transactionRequest)
        });
        const data = await response.json();
        if (response.status === 400) {
            throw new Error((data as HttpError).detail);
        } else if (response.status === 500) {
            // TODO toch consumer message hier tonen, omdat die beter is!
            // See Merchant integration gids 5.4
            throw new Error('Op dit moment is betalen met iDEAL helaas niet mogelijk. Probeer het op een later moment nog eens of gebruik een andere betaalmethode');
            // throw new Error('500: Server error. Please contact your administrator');
        }
        return data;
    }
}
