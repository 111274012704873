import {StatusModel} from './Status.model';
import {HttpError} from '../http/HttpError';

export class StatusService {
    public static async retrieveStatus(invoiceId: string): Promise<StatusModel> {
        const response = await fetch('status', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify({
                InvoiceId: invoiceId
            })
        });
        const data = await response.json();
        if (response.status === 400) {
            throw new Error((data as HttpError).detail);
        } else if (response.status === 500) {
            throw new Error("500: Server error. Please contact your administrator");
        }
        return data;
    }
}
