import './IdealSummary.scss';
import React, {useContext} from 'react';
import {Form, FormGroup} from 'reactstrap';
import {IdealContext} from '../../../ideal/IdealContext.component';
import Summary from '../../Summary/Summary';

const IdealSummary: React.FC = () => {
    const [state] = useContext(IdealContext);
    return (
        <Form className={'idealSummaryForm'}>
            <FormGroup>
                <div className={'toPay'}>Te betalen: {state.invoice?.declarationAmount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' }) }</div>
                <div>Betaal online aan SPAWW: makkelijk, veilig en snel. Kies uw bank en betaal meteen.</div>
            </FormGroup>
            <Summary/>
        </Form>
    )
}
export default IdealSummary;
