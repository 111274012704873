import './PaymentFailed.scss';
import React, {useContext} from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import PaymentFailedSummary from './PaymentFailedSummary/PaymentFailedSummary';
import {useInvoice} from '../../invoice/useInvoice';
import {useStatus} from '../../status/useStatus';
import {IdealContext} from '../../ideal/IdealContext.component';
import {useSearchParameters} from '../../http/useSearchParameters';

const PaymentFailed: React.FC = () => {
    const [state] = useContext(IdealContext);
    // const invoice = useInvoice();
    // const status = useStatus();
    const {returnurl} = useSearchParameters();
    const onRedirect = () => {
        if (returnurl) {
            window.location.href = decodeURI(returnurl);
        }
    }
    // TODO foutmelding van iDEAL tonen
    return (
        <Container className="paymentFailed-container">
            <Row className={'logo'}>
                <Col className={'imageCol'} xs={'2'}><img alt={'SPAWW logo'} src={'/logo.png'}/></Col>
            </Row>
            {/*{!(invoice && status) &&*/}
            {/*<Row><img className={'spinner'} alt={'spinner'} src={'/spinner.gif'}/></Row>*/}
            {/*}*/}
            {/*{invoice && status &&<>*/}
                <Row className={'content'}>
                    <Col className={'summary'}><PaymentFailedSummary/></Col>
                </Row>
                <Row className={'footer'}>
                    <div>
                        <Button onClick={onRedirect} color={'primary'}>Terugkeren naar PAWW portaal</Button>
                    </div>
                </Row>
            {/*</>}*/}
        </Container>
    )
}
export default PaymentFailed;
