import './PaymentOpen.scss';
import React from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import PaymentOpenSummary from './PaymentOpenSummary/PaymentOpenSummary';
import {useInvoice} from '../../invoice/useInvoice';
import {useStatus} from '../../status/useStatus';
import {useSearchParameters} from '../../http/useSearchParameters';

const PaymentFailed: React.FC = () => {
    // const invoice = useInvoice();
    // const status = useStatus();
    const {returnurl} = useSearchParameters();
    const onRedirect = () => {
        if (returnurl) {
            window.location.href = decodeURI(returnurl);
        }
    }
    // TODO foutmelding van iDEAL tonen

    // TODO Aangeven hoe hij de status kan ophalen (Merchant Integration gids 6.4 en 6.5)
    return (
        <Container className="paymentOpen-container">
            <Row className={'logo'}>
                <Col className={'imageCol'} xs={'2'}><img alt={'SPAWW logo'} src={'/logo.png'}/></Col>
            </Row>
            {/*{!(invoice && status) &&*/}
            {/*<Row><img className={'spinner'} alt={'spinner'} src={'/spinner.gif'}/></Row>*/}
            {/*}*/}
            {/*{invoice && status &&<>*/}
                <Row className={'content'}>
                    <Col className={'summary'}><PaymentOpenSummary/></Col>
                </Row>
                <Row className={'footer'}>
                    <div>
                        <Button onClick={onRedirect} color={'primary'}>Terugkeren naar PAWW portaal</Button>
                    </div>
                </Row>
            {/*</>}*/}
        </Container>
    )
}
export default PaymentFailed;
