import './App.scss'
import React  from 'react';
import {Route,Routes, BrowserRouter as Router} from 'react-router-dom';
import Layout from './components/Layout';
import IdealContextComponent from './ideal/IdealContext.component';
import Ideal from './components/Ideal/Ideal';
import {ToastContainer} from 'react-toastify';
import PaymentSuccess from './components/PaymentSuccess/PaymentSuccess';
import PaymentDone from './components/PaymentDone/PaymentDone';
import PaymentFailed from './components/PaymentFailed/PaymentFailed';
import PaymentOpen from './components/PaymentOpen/PaymentOpen';

const App: React.FC = () => {
    return (
        <IdealContextComponent>
            <ToastContainer newestOnTop={false} pauseOnFocusLoss draggable/>
            <Router>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Ideal />} />
                        <Route path="paymentdone/*" element={<PaymentDone />} />
                        <Route path="paymentsuccess/*" element={<PaymentSuccess />} />
                        <Route path="paymentfailed/*" element={<PaymentFailed />} />
                        <Route path="paymentopen/*" element={<PaymentOpen />} />
                    </Routes>
                </Layout>
            </Router>
        </IdealContextComponent>
    );
}
export default App;
