import './PaymentDone.scss';
import React, {useContext, useEffect} from 'react';
import {Col, Container, Row} from 'reactstrap';
import {IdealContext} from '../../ideal/IdealContext.component';
import {useNavigate } from 'react-router-dom';
import {StatusService} from '../../status/Status.service';
import {toast} from 'react-toastify';
import {useInvoice} from '../../invoice/useInvoice';

const PaymentDone: React.FC = () => {
    const [state, {setStatus}] = useContext(IdealContext);
    const invoice = useInvoice();
    const navigate = useNavigate ();
    useEffect(() => {
        const getStatus = async () => {
            if (invoice) {
                try {
                    let status = state.status;
                    if (!status) {
                        status = await StatusService.retrieveStatus(invoice.invoiceId);
                        setStatus(status);
                    }
                    // https://dev.to/projectescape/programmatic-navigation-in-react-3p1l
                    if (status) {
                        if (status.status === 'Success') {
                            navigate(`/paymentsuccess${window.location.search}`);
                        } else if (['Failure', 'Cancelled', 'Expired'].includes(status.status)) {
                            navigate(`/paymentfailed${window.location.search}`);
                        } else if (status.status === 'Open') {
                            navigate(`/paymentopen${window.location.search}`);
                        }
                    }
                } catch (e:any) {
                    toast.warn(e.message, {
                        position: toast.POSITION.TOP_LEFT,
                        autoClose: false,
                        closeOnClick: true,
                    });
                }
            }
        }
        if (invoice) {
            getStatus();
        }
    }, [invoice]);

    return (
        <Container className="paymentDone-container">
            <Row className={'logo'}>
                <Col className={'imageCol'} xs={'2'}><img alt={'SPAWW logo'} src={'/logo.png'}/></Col>
            </Row>
            <Row><img className={'spinner'} alt={'spinner'} src={'/spinner.gif'}/></Row>
        </Container>
    )
}
export default PaymentDone;
