import {IdealState} from './IdealState';
import {DirectoryModel} from '../directory/Directory.model';
import {InvoiceModel} from '../invoice/Invoice.model';
import {StatusModel} from '../status/Status.model';
import {TransactionModel} from '../transaction/Transaction.model';

type Action =
    | {type: 'SET_DIRECTORYSELECTION', payload: DirectoryModel[]}
    | {type: 'SELECT_DIRECTORY', payload: DirectoryModel |  undefined}
    | {type: 'SET_INVOICE', payload: InvoiceModel}
    | {type: 'SET_TRANSACTION', payload: TransactionModel}
    | {type: 'SET_STATUS', payload: StatusModel}
    | {type: 'SET_COUNT', payload: number}

export const idealReducer = (state: IdealState, action: Action): IdealState => {
    switch(action.type) {
        case 'SET_DIRECTORYSELECTION': {
            return {
                ...state,
                directorySelection: action.payload,
            }
        }
        case 'SELECT_DIRECTORY': {
            return {
                ...state,
                selectedDirectory: action.payload,
            }
        }
        case 'SET_INVOICE': {
            return {
                ...state,
                invoice: action.payload,
            }
        }
        case 'SET_TRANSACTION': {
            return {
                ...state,
                transaction: action.payload,
            }
        }
        case 'SET_STATUS': {
            return {
                ...state,
                status: action.payload,
            }
        }
        case 'SET_COUNT': {
            return {
                ...state,
                currentCount: action.payload,
            }
        }
    }
}
