import {InvoiceModel} from './Invoice.model';
import {HttpError} from '../http/HttpError';

export class InvoiceService {
    public static async retrieveInvoice(invoiceId: string, accountId: string): Promise<InvoiceModel> {
        const response = await fetch('invoice?' + new URLSearchParams({invoiceId: invoiceId, accountId: accountId}));
        const data = await response.json();
        if (response.status === 400) {
            throw new Error((data as HttpError).detail);
        } else if (response.status === 500) {
            throw new Error("500: Server error. Please contact your administrator");
        }
        return data;
    }
}
