import {IdealState} from './IdealState';
import {idealReducer} from './Ideal.reducer';
import {useEffect, useReducer} from 'react';
import {DirectoryModel} from '../directory/Directory.model';
import {InvoiceModel} from '../invoice/Invoice.model';
import {StatusModel} from '../status/Status.model';
import {TransactionModel} from '../transaction/Transaction.model';

export type IdealType = [IdealState, {
    setDirectorySelection: (directories: DirectoryModel[]) => void;
    selectDirectory: (directory?: DirectoryModel) => void;
    setInvoice: (invoice: InvoiceModel) => void;
    setTransaction: (transaction: TransactionModel) => void;
    setStatus: (status: StatusModel) => void;
    setCount: (currentCount: number) => void;
}];

const initialState: IdealState = {
    currentCount: 0,
    directorySelection: [],
}

export const useIdealApi = (): IdealType => {
    const [state, dispatch] = useReducer(idealReducer, {...initialState});

    const setDirectorySelection = (directorySelection: DirectoryModel[]): void => {
        dispatch({type: 'SET_DIRECTORYSELECTION', payload: directorySelection});
    }
    const selectDirectory = (directory?: DirectoryModel): void => {
        dispatch({type: 'SELECT_DIRECTORY', payload: directory});
    }
    const setInvoice = (invoice: InvoiceModel): void => {
        dispatch({type: 'SET_INVOICE', payload: invoice});
    }
    const setTransaction = (transaction: TransactionModel) => {
        dispatch({type: 'SET_TRANSACTION', payload: transaction});
    }
    const setStatus = (status: StatusModel): void => {
        dispatch({type: 'SET_STATUS', payload: status})
    }
    const setCount = (currentCount: number): void => {
        dispatch({type: 'SET_COUNT', payload: currentCount});
    }
    return [state, {setDirectorySelection, selectDirectory, setCount, setInvoice, setTransaction, setStatus}];
}
