import './PaymentSuccess.scss';
import React from 'react';
import {Button, Col, Container, Row} from 'reactstrap';
import PaymentSuccessSummary from './PaymentSuccessSummary/PaymentSuccessSummary';
import {useInvoice} from '../../invoice/useInvoice';
import {useStatus} from '../../status/useStatus';
import {useSearchParameters} from '../../http/useSearchParameters';

const PaymentSuccess: React.FC = () => {
    // const invoice = useInvoice();
    // const status = useStatus();
    const {returnurl} = useSearchParameters();
    const onRedirect = () => {
        if (returnurl) {
            window.location.href = decodeURI(returnurl);
        }
    }
    return (
        <Container className="paymentSuccess-container">
            <Row className={'logo'}>
                <Col className={'imageCol'} xs={'2'}><img alt={'SPAWW logo'} src={'/logo.png'}/></Col>
            </Row>
            {/*{!(invoice && status) &&*/}
            {/*    <Row><img className={'spinner'} alt={'spinner'} src={'/spinner.gif'}/></Row>*/}
            {/*}*/}
            {/*{invoice && status &&<>*/}
                <Row className={'content'}>
                    <Col className={'summary'}><PaymentSuccessSummary/></Col>
                </Row>
                <Row className={'footer'}>
                    <div>
                        <Button onClick={onRedirect} color={'primary'}>Terugkeren naar PAWW portaal</Button>
                    </div>
                </Row>
            {/*</>}*/}
        </Container>
    )
}
export default PaymentSuccess;
