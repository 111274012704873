import {useLocation} from 'react-router';
import {useEffect, useState} from 'react';

interface Parameters {
    id?: string; // Invoice id
    aid?: string; // Account id
    returnurl?: string;
}

const extractSearchParameters = (search: string): Parameters => {
    const params: any = {};
    if (search) {
        const searchTrim = search.substr(1);
        const splits = searchTrim.split('&');
        for (const split of splits) {
            const keyValueSplit = split.split('=');
            params[keyValueSplit[0]] = keyValueSplit[1].replace("%3A",":");
        }
    }
    return params;
}

export const useSearchParameters = () => {
    const [searchParameters, setSearchParameters] = useState<Parameters>({});
    const location = useLocation();
    useEffect(() => {
        if (location.search) {
            const params = extractSearchParameters(location.search);
            setSearchParameters(params);
        }
    }, [location.search]);
    return searchParameters;
}
