import {useContext, useEffect, useState} from 'react';
import {InvoiceService} from './Invoice.service';
import {toast} from 'react-toastify';
import {IdealContext} from '../ideal/IdealContext.component';
import {useSearchParameters} from '../http/useSearchParameters';





export const useInvoice = () => {
    const [state, {setInvoice}] = useContext(IdealContext);
    const [invoiceid, setInvoiceid] = useState<string>('');
    const [accountid, setAccountid] = useState<string>('');
    const {id, aid} = useSearchParameters();

    useEffect(() => {
        if (id) {
            setInvoiceid(id);
        }
    }, [id]);

    useEffect(() => {
        if (aid) {
            setAccountid(aid);
        }
    }, [aid]);

    useEffect(() => {
        const populateInvoice = async () => {
            try {
                const invoice = await InvoiceService.retrieveInvoice(invoiceid, accountid);
                setInvoice(invoice);
            } catch (e: any) {
                toast.warn(e.message, {
                    position: toast.POSITION.TOP_LEFT,
                    autoClose: false,
                    closeOnClick: true,
                });
            }
        }
        if (invoiceid && accountid && !state.invoice) {
            populateInvoice();
        }
    }, [invoiceid, accountid]);
    return state.invoice;
}
