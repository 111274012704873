import React, {useContext} from 'react';
import {FormGroup} from 'reactstrap';
import {IdealContext} from '../../ideal/IdealContext.component';

const Summary: React.FC = () => {
    const [state] = useContext(IdealContext);
    return <>
        <FormGroup>
            <div className={'description'}>Omschrijving:</div>
            <div>Notanummer {state.invoice?.invoiceNumber} {state.invoice?.name}</div>
        </FormGroup>
        <FormGroup>
            <div className={'reference'}>Betalingskenmerk:</div>
            <div>Notanummer {state.invoice?.invoiceNumber} Bijdrage PAWW</div>
        </FormGroup>
    </>
}
export default Summary;
