import './PaymentOpenSummary.scss';
import React, {useContext} from 'react';
import {Form, FormGroup} from 'reactstrap';
import {IdealContext} from '../../../ideal/IdealContext.component';
import Summary from '../../Summary/Summary';

const PaymentOpenSummary: React.FC = () => {
    const [state] = useContext(IdealContext);
    return (
        <Form className={'paymentOpenSummaryForm'}>
            <FormGroup>
                <div className={'payment-open'}>Betaling (nog) niet bevestigd door uw bank: {state.invoice?.declarationAmount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })}</div>
                <div>We hebben van uw bank nog geen bevestiging ontvangen.
                    Als u in uw Internetbankieren ziet dat uw betaling heeft plaatsgevonden, zullen wij na ontvangst van de betaling dit verwerken in ons systeem.</div>
            </FormGroup>
            <Summary/>
            <FormGroup>
                <div className={'status'}>Status:</div>
                <div>Niet bevestigd</div>
            </FormGroup>
        </Form>
    )
}
export default PaymentOpenSummary;
