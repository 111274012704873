import './PaymentSuccessSummary.scss';
import React, {useContext} from 'react';
import {Form, FormGroup} from 'reactstrap';
import {IdealContext} from '../../../ideal/IdealContext.component';
import Summary from '../../Summary/Summary';

const PaymentSuccessSummary: React.FC = () => {
    const [state] = useContext(IdealContext);
    return (
        <Form className={'paymentSuccessSummaryForm'}>
            <FormGroup>
                <div className={'paid'}>Betaling geslaagd: {state.invoice?.declarationAmount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })}</div>
                <div>Bedankt voor uw betaling.</div>
            </FormGroup>
            <Summary/>
        </Form>
    )
}
export default PaymentSuccessSummary;
