import './DirectorySelection.scss';
import React, {useContext, useEffect} from 'react';
import {Col, Container, Form, FormGroup, Input, Row} from 'reactstrap';

import {IdealContext} from '../../ideal/IdealContext.component';
import {toast} from 'react-toastify';

const DirectorySelection: React.FC = () => {
    const [state, {selectDirectory, setDirectorySelection}] = useContext(IdealContext);
    return (
        <Form className={'bankform'}>
            <FormGroup>
                <Container>
                    <Row>
                        <Col className={'imageCol'} xs={'2'}><img alt={'iDEAL logo'} src={'/ideal.png'} width={'70px'}/></Col>
                        <Col xs={'auto'}>
                            <div className={'line1'}>Betalen met iDEAL</div>
                            <div>Online betalen via uw bank</div>
                        </Col>
                    </Row>
                </Container>
            </FormGroup>
        </Form>
    )
}
export default DirectorySelection;
