import React from 'react';
import {IdealType, useIdealApi} from './Ideal.api';
// @ts-ignore
export const IdealContext = React.createContext<IdealType>(null);

interface IdealComponentProps {}

const IdealContextComponent: React.FC<IdealComponentProps> = (idealComponentProps:any) => {
    const idealApi = useIdealApi();
    return (
        <IdealContext.Provider value={idealApi}>
            {idealComponentProps.children}
        </IdealContext.Provider>
    )
}
export default IdealContextComponent;
