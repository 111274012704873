import './PaymentFailedSummary.scss';
import React, {useContext} from 'react';
import {Col, Form, FormGroup} from 'reactstrap';
import {IdealContext} from '../../../ideal/IdealContext.component';
import Summary from '../../Summary/Summary';

const PaymentFailedSummary: React.FC = () => {
    const [state] = useContext(IdealContext);
    return (
        <Form className={'paymentFailedSummaryForm'}>
            <FormGroup>
                <div className={'paid-failed'}>Betaling niet geslaagd: {state.invoice?.declarationAmount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })}</div>
                <div>Betaling niet geslaagd, want&#160;
                    {state.status?.status === 'Cancelled' && `U heeft de transactie afgebroken.`}
                    {state.status?.status === 'Expired' && `de betaling is verlopen.`}
                    {state.status?.status === 'Failure' && `er is een fout opgetreden.`}
                </div>
            </FormGroup>
            <Summary/>
            <FormGroup>
                <div className={'status'}>Status:</div>
                <div>{state.status?.status === 'Cancelled' && `Geannuleerd`}
                    {state.status?.status === 'Failure' && `Systeemfout`}
                    {state.status?.status === 'Expired' && `Verlopen`}
                </div>
            </FormGroup>
        </Form>
    )
}
export default PaymentFailedSummary;
