import './Ideal.scss';
import React, {useContext, useEffect, useState} from 'react';
import {IdealContext} from '../../ideal/IdealContext.component';
import {Button, Col, Container, Row} from 'reactstrap';
import DirectorySelection from '../DirectorySelection/DirectorySelection';
import IdealSummary from './IdealSummary/IdealSummary';
import {useInvoice} from '../../invoice/useInvoice';
import {TransactionService} from '../../transaction/Transaction.service';
import {useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify';
import {StatusService} from '../../status/Status.service';
import {useSearchParameters} from '../../http/useSearchParameters';

const Ideal: React.FC = () => {
    const [state, {setTransaction, setStatus}] = useContext(IdealContext);
    const invoice = useInvoice();
    const navigate = useNavigate ();
    const {returnurl} = useSearchParameters();
    const [busy, setBusy] = useState<boolean>(false);
    const onCancel = () => {
        if (returnurl) {
            window.location.href = returnurl;
        }
    }
    const onIdeal = async () => {
        if (invoice && !busy) {
            try {
                setBusy(true);
                const transaction = await TransactionService.createTransaction(invoice.invoiceId, "");
                setTransaction(transaction);
                setBusy(false);
                window.location.href = transaction.issuerAuthenticationURL;
            } catch (e:any) {
                setBusy(false);
                toast.warn(e.message, {
                    position: toast.POSITION.TOP_LEFT,
                    autoClose: false,
                    closeOnClick: true,
                });
            }
        }
    }

    // useEffect(() => {
    //     if (state.transaction) {
    //         // const issuerAuthenticationURL = state.transaction.issuerAuthenticationURL;
    //     }
    // }, [state.transaction]);

    useEffect(() => {
        const getStatus = async () => {
            if (invoice) {
                try {
                    const status = await StatusService.retrieveStatus(invoice.invoiceId);
                    setStatus(status);

                    // User returned and probably used back button. See Merchant Integratie Gids 5.7
                    if (['Success', 'Open'].includes(status.status)) { // 'Failure', 'Cancelled', 'Expired',
                        navigate(`/paymentdone${window.location.search}`);
                    }
                } catch (e:any) {
                    toast.warn(e.message, {
                        position: toast.POSITION.TOP_LEFT,
                        autoClose: false,
                        closeOnClick: true,
                    });
                }
            }
        }
        if (invoice) {
            getStatus();
        }
    }, [invoice]);

    return (<Container className="ideal-container">
        <Row className={'logo'}>
            <Col className={'imageCol'} xs={'2'}><img alt={'SPAWW logo'} src={'/logo.png'}/></Col>
        </Row>
        {!invoice &&
            <Row><img className={'spinner'} alt={'spinner'} src={'/spinner.gif'}/></Row>
        }
        {invoice &&<>
            <Row className={'content'}>
                <Col className={'summary'}><IdealSummary/></Col>
                <Col className={'bankselection'}><DirectorySelection/></Col>
            </Row>
            <Row className={'footer'}>
                <div>
                    {returnurl && <Button className={'cancelButton'} onClick={onCancel}>Betaling annuleren</Button>}
                    <Button onClick={onIdeal} color={'primary'} disabled={busy}>Bedrag betalen</Button>
                </div>
            </Row>
        </>}
    </Container>)
};
export default Ideal;
